import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Zeroconf } from '@ionic-native/zeroconf/ngx';
import { Platform } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
import { timeout } from 'rxjs/operators';
const { Network } = Plugins;


@Injectable({
    providedIn: 'root'
})
export class DnsService implements OnInit {

    endpoints: { [key: string]: { url: string, token: string } } = {};
    subscription: any;
    //scanTimeout;

    constructor(private zeroconf: Zeroconf,
        private platform: Platform,
        private http: HttpClient
    ) {
        zeroconf.registerAddressFamily = 'ipv4'; // or 'ipv6' ('any' by default)
        zeroconf.watchAddressFamily = 'ipv4'; // or 'ipv6' ('any' by default)
        this.scanLocalIPAddress();
    }
    ngOnInit(): void {

        Network.addListener('networkStatusChange', () => {
            this.clear();
        })
    }

    wait(seconds) {
        return new Promise<any>((resolve, reject) => {
            setTimeout(() => {
                resolve(null);
            }, seconds * 1000);
        })
    }

    clear() {
        this.endpoints = {};
    }

    getEndpoint = async (uuid: string) => {
        let endPoint = this.endpoints[uuid];

        /*
        if (!endPoint) {
            if (await this.ping(uuid)) {
                this.endpoints[uuid] = { url: 'bf-' + uuid, token: null };
                endPoint = this.endpoints[uuid];
            }
        }
        */
        return endPoint;
    }

    ping = async (uuid: string) => {
        try {
            let r: any = await this.http.get(`http://bf-${uuid}`).pipe(timeout(1000)).toPromise();
            if (r && r.uuid == uuid) {
                console.info("ping succcess");
                return true;
            }
            else {
                console.info("ping failed");
                return false;
            }
        }
        catch (ex) {
            return false;
        }
    }


    queryEndpoint = async (uuid: string, waitTime?: number) => {

        let endpoint = await this.getEndpoint(uuid);
        let loop = 0;

        while (endpoint && loop < waitTime * 10) {
            await this.wait(waitTime / 10);
            endpoint = await this.getEndpoint(uuid);
            console.info("loop", loop)
            loop++;
        }

        return await this.getEndpoint(uuid);
    }


    /*
    stopScanning = () => {
        console.info("stopping scanning mdns");
        try {
            return this.zeroconf.unwatch('_http._tcp.', 'local.').catch((ex)=>
            {
                console.info("error in stopping scanning mdns");
            })
        }
        catch (ex) {
            console.info("error in unwatching mdns", ex);
        }
        console.info("stopped scanning mdns");
    }
    */

    scanLocalIPAddress = () => {
        if (this.platform.is('cordova')) {
            if (!this.subscription) {
                this.subscription = this.zeroconf.watch('_http._tcp.', 'local.').subscribe(
                    result => {
                        //console.info("found service", result.service);

                        if (result.action == 'resolved') {
                            //console.log('service added', result.service);
                            if (result.service.name.startsWith("bf-")) {
                                console.info("found service", result);
                                let uuid = result.service.name.substr(3, 8);
                                let ips = (result.service.ipv4Addresses);
                                if (ips.length > 0) {
                                    if (ips[0].startsWith("192.168.") || ips[0].startsWith("172.") || ips[0].startsWith("10.")) {
                                        console.info("resolving ip", ips[0]);
                                        let endpoint = { url: ips[0], token: "" };
                                        this.endpoints[uuid] = endpoint;
                                    }
                                }
                            }
                        } else {
                            //console.log('service removed', result.service);
                        }
                    },
                );
            }
        }
    }
}
