import { Injectable, OnInit } from '@angular/core';
import { SessionService } from './session.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform, ToastController } from '@ionic/angular';
import { Monitor } from '../main/entities/alert';

import {
    Plugins,
    PushNotification,
    PushNotificationToken,
    PushNotificationActionPerformed
} from '@capacitor/core';
import { Subject } from 'rxjs';
import { debounceTime, timeout } from 'rxjs/operators';
import { LocalServerService } from './local-server.service';
import * as moment from 'moment';

const { PushNotifications } = Plugins;

@Injectable({
    providedIn: 'root'
})
export class RioService {
   
    registerMobileDebouncer = new Subject<PushNotificationToken>();

    constructor(
        public http: HttpClient,
        public session: SessionService,
        public platform: Platform,
        public toastControl: ToastController,
        public localServer: LocalServerService
    ) {
        session.getRefreshToken = (refreshToken) => {
            return this.getRefreshToken(refreshToken);
        }

        //to prevent double call for push notification registration.
        this.registerMobileDeounce();
    }
    //idServiceUrl = 'http://localhost:8001'; // "https://id.growos.bifarm.com";
    idServiceUrl = "https://rio.growos.io";

    async login(email: string, password: string): Promise<any> {
        let loginInfo: { email: string, password: string } = { email: email, password: password };
        return this.http.post(this.idServiceUrl + "/auth", loginInfo, { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) })
            .toPromise();
    }

    /*
    async checkEmailExistsPost(email: string): Promise<any> {
        return this.http.post(this.idServiceUrl + "/users/" + email, null, { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) })
            .toPromise();
    }
*/

    async register(email: string, password: string): Promise<{ error?: string, user: { id: string, email: string, name: string, confirmed: boolean }, authToken: string, refreshToken: string }> {
        let registerInfo: { password: string, email: string } = { password: password, email: email };
        return this.http.post(this.idServiceUrl + "/users", registerInfo, { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) })
            .toPromise() as Promise<{ user: { id: string, email: string, name: string, confirmed: boolean }, authToken: string, refreshToken: string }>;
    }

    async requestPasswordReset(email: string): Promise<any> {
        return this.http.get(`${this.idServiceUrl}/users/${email}/passwordResetRequest`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) })
            .toPromise();
    }

    async updatePassword(password: string, resetCode: string, email: string): Promise<any> {
        let info = { password: password, resetCode: resetCode }
        return this.http.post(`${this.idServiceUrl}/users/${email}/passwordReset`, info, { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) })
            .toPromise();
    }

    async requestConfirmationCode(email: string): Promise<any> {
        return this.http.get(`${this.idServiceUrl}/users/${email}/confirmationCodeRequest`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) })
            .toPromise();
    }

    async confirmUser(email: string, passcode: string): Promise<{ confirmed?: boolean, error?: string }> {
        let passcodeInfo = { passcode: passcode }
        return this.http.post(`${this.idServiceUrl}/users/${email}/confirm`, passcodeInfo, { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) })
            .toPromise() as Promise<{ confirmed?: boolean, error?: string }>;
    }

    async getRefreshToken(refreshToken: string): Promise<any> {
        return this.http.get(this.idServiceUrl + "/auth/refresh", { headers: new HttpHeaders({ 'refreshtoken': refreshToken }) }).pipe(timeout(5000))
            .toPromise();
    }


    async getUserProfile(): Promise<any> {
        return this.http.get(this.idServiceUrl + "/account/profile", { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    async updateUserProfile(user: { name: string, notificationProfile: any }): Promise<any> {
        return this.http.post(this.idServiceUrl + "/account/profile", user, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    async deleteAccount(user: { name: string; }) {
        return this.http.post(this.idServiceUrl + "/account/delete", user, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    // facility collection change
    async createNewFacility(facility: any): Promise<any> {
        return this.http.post(this.idServiceUrl + "/account/facilities", facility, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    async deleteFacility(facilityId: string): Promise<any> {
        return this.http.delete(this.idServiceUrl + "/account/facilities/" + facilityId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    async getFacilities(): Promise<any> {
        return this.http.get(this.idServiceUrl + "/account/facilities", { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    // operations
    async getFacility(facilityId: string): Promise<any> {
        console.info("this.localServer.facility", this.localServer.endpoint)
        if (this.localServer.endpoint) {
            await this.localServer.queryFacilityOnServer();
        }

        console.info("this.localServer.facility", this.localServer.facility)
        if (this.localServer.facility && this.localServer.facility.id === facilityId) {
            return this.localServer.facility;
        }
        else {
            return this.http.get(this.idServiceUrl + `/account/facilities/${facilityId}`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                .toPromise();
        }
    }

    async getFacilityAcl(facilityId: string): Promise<any> {
        return this.http.get(this.idServiceUrl + `/account/facilities/${facilityId}/acl`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    async updateFacility(facilityId: string, facility: any): Promise<any> {
        let r = await this.localServer.updateFacility(facilityId, facility)
        if (r)
            return r;
        else
            return this.http.post(this.idServiceUrl + `/account/facilities/${facilityId}`, facility, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                .toPromise();
    }

    async updateServer(facilityId: string, serverUUID: any, domain: any): Promise<any> {
        serverUUID = serverUUID ? serverUUID.trim() : 'null';
        if (serverUUID == '')
            serverUUID = 'null'

        domain = domain ? domain.trim() : 'null';

        return this.http.post(this.idServiceUrl + `/account/facilities/${facilityId}/server/${serverUUID}`, { domain: domain }, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    async upateFacilityAcl(facilityId: string, email: string, role: string): Promise<any> {
        return this.http.post(this.idServiceUrl + `/account/facilities/${facilityId}/acl`, { email: email, role: role }, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    async getFacilityAvailableDeviceUUIDs(facilityId: string): Promise<any> {

        let uuids = await this.localServer.getFacilityAvailableDeviceUUIDs(facilityId);
        if (uuids) {
            return uuids;
        }
        else
            return this.http.get(this.idServiceUrl + `/account/facilities/${facilityId}/devices/available`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                .toPromise();
    }


    async deleteSpace(facilityId: string, spaceId: string): Promise<any> {
        let r = await this.localServer.deleteSpace(facilityId, spaceId);
        if (r) {
            return r;
        }
        else
            return this.http.delete(this.idServiceUrl + `/account/facilities/${facilityId}/spaces/${spaceId}`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                .toPromise();
    }

    async getSpaces(facilityId: string): Promise<any> {
        let r = await this.localServer.getSpaces(facilityId);
        if (r)
            return r;
        else
            return this.http.get(this.idServiceUrl + `/account/facilities/${facilityId}/spaces`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                .toPromise();
    }

    async createNewSpace(facilityId: string, spaceInfo: any): Promise<any> {
        let r = await this.localServer.createNewSpace(facilityId, spaceInfo);
        console.info("results of creating space", r);
        if (r) {
            return r;
        }
        else
            return this.http.post(this.idServiceUrl + `/account/facilities/${facilityId}/spaces`, spaceInfo, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                .toPromise();
    }

    async getSpace(facilityId: string, spaceId: string): Promise<any> {
        let r = await this.localServer.getSpace(facilityId, spaceId);
        if (r)
            return r;
        else
            return this.http.get(this.idServiceUrl + `/account/facilities/${facilityId}/spaces/${spaceId}`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                .toPromise();
    }

    async updateSpaceInfo(facilityId: string, spaceId: string, spaceInfo: any): Promise<any> {

        let r = await this.localServer.updateSpaceInfo(facilityId, spaceId, spaceInfo);
        if (r)
            return r;
        else
            return this.http.post(this.idServiceUrl + `/account/facilities/${facilityId}/spaces/${spaceId}`, spaceInfo, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                .toPromise();
    }

    async updateSpaceController(facilityId: string, spaceId: string, uuid: string): Promise<any> {
        let r = await this.localServer.updateSpaceController(facilityId, spaceId, uuid);
        if (r)
            return r;
        else
            return this.http.post(this.idServiceUrl + `/account/facilities/${facilityId}/spaces/${spaceId}/controller`, { facilityControllerUUID: uuid }, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                .toPromise();
    }

    async updateSpaceUnits(facilityId: string, spaceId: string, units: any[]): Promise<any> {

        let r = await this.localServer.updateSpaceUnits(facilityId, spaceId, units);
        if (r)
            return r;
        else
            return this.http.post(this.idServiceUrl + `/account/facilities/${facilityId}/spaces/${spaceId}/units`, { name: name, units: units }, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                .toPromise();
    }

    async provisionDeviceToFacility(facilityId: string, uuid: string): Promise<any> {
        let r: any = await this.http.post(this.idServiceUrl + `/account/facilities/${facilityId}/devices/${uuid}/provision`, null, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();

        console.info("r", r);

        if (r && !r.error) {
            if (r.status == 'provisioned' || r.status == 'unused') {
                await this.localServer.provisionDeviceToFacility(facilityId, uuid);
            }
        }

        return r;
    }

    async deprovisionDeviceFromFacility(facilityId: string, uuid: string): Promise<any> {
        let r: any = await this.http.delete(this.idServiceUrl + `/account/facilities/${facilityId}/devices/${uuid}/provision`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
        if (r && !r.error) {
            if (r.status == 'unprovisioned' || r.status == 'unused') {
                await this.localServer.deprovisionDeviceFromFacility(facilityId, uuid);
            }
        }
        return r;
    }

    async queryDeviceProvisionInfo(facilityId: string, uuid: string): Promise<any> {
        return this.http.get(this.idServiceUrl + `/account/facilities/${facilityId}/devices/${uuid}/provision`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    async updateSpaceGrowPlan(facilityId: string, spaceId: string, growplan: any) {
        let r: any = await this.localServer.updateSpaceGrowPlan(facilityId, spaceId, growplan);
        console.info("before appy growplan. r is", r);
        if (r && !r.error && Array.isArray(r)) {

            /*
            r.forEach(
                async unit => {
                    this.http.post(`http://bf-${unit.controllerUUID}/rpc/v4/growplanRuntime/load`, { growplan: growplan })
                        .toPromise();
                }
            )
            */
            return r;
        }

        else
            return this.http.post(this.idServiceUrl + `/account/facilities/${facilityId}/spaces/${spaceId}/growplan`, growplan, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                .toPromise();

    }

    async removeSpaceGrowPlan(facilityId: string, spaceId: string) {
        let r = await this.localServer.removeSpaceGrowPlan(facilityId, spaceId);
        if (r && !r.error && Array.isArray(r)) {
            return r;
        }

        else
            return this.http.delete(this.idServiceUrl + `/account/facilities/${facilityId}/spaces/${spaceId}/growplan`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                .toPromise();

    }

    async getLibrary(facilityId: string): Promise<any> {

        let r: any;
        if (this.localServer.endpoint) {

            r = await this.localServer.queryLibraryOnServer();
        }

        if (r)
            return r;
        else {
            if (facilityId !== 'personal') {
                return this.http.get(this.idServiceUrl + `/account/facilities/${facilityId}/library`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                    .toPromise();
            }
            else {
                return this.http.get(this.idServiceUrl + "/account/library", { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                    .toPromise();
            }
        }
    }
    async updateLibrary(library: { growplans: any[] }, facilityId: string): Promise<any> {
        let r: any;

        if (this.localServer.endpoint) {
            r = await this.localServer.updateLibraryOnServer(library);
        }

        console.info("updating localserver library", r);

        if (r && !r.error)
            return r;
        else {
            if (facilityId !== 'personal') {
                return this.http.post(this.idServiceUrl + `/account/facilities/${facilityId}/library`, library, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                    .toPromise();
            }
            else {
                return this.http.post(this.idServiceUrl + "/account/library", library, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
                    .toPromise();
            }
        }
    }

    async updateSpaceMonitors(facilityId: string, spaceId: string, monitorConfigurations: Monitor[]) {
        return this.http.post(this.idServiceUrl + `/account/facilities/${facilityId}/spaces/${spaceId}/monitors`, monitorConfigurations, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    async getSpaceMonitors(facilityId: string, spaceId: string) {
        return this.http.get(this.idServiceUrl + `/account/facilities/${facilityId}/spaces/${spaceId}/monitors`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    async getCases(facilityId: string, spaceId: string, from: number, to: number, limit: number) {
        let params: any = { facilityId: facilityId, spaceId: spaceId, unitId: null, from: from || moment().add(-1, 'day').valueOf(), to: to || moment().valueOf(), limit: 50 };

        return this.http.get(this.idServiceUrl + `/account/facilities/${facilityId}/spaces/${spaceId}/cases`, { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    async updateCase(facilityId: string, event: {
        caseId: string;
        event: {
            title: string;
            details?: string;
            data?: any;
            context?: { [key: string]: any };
        };
        closing: boolean;
    }) {
        return this.http.post(this.idServiceUrl + `/account/facilities/${facilityId}/cases/${event.caseId}`, event, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    private async registerTerminal(token: string, platform: string) {
        return this.http.post(this.idServiceUrl + "/account/terminals", { token: token, platform: platform }, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }
    private async unregisterTerminal(token: string, platform: string) {
        return this.http.post(this.idServiceUrl + "/account/terminals/remove", { token: token, platform: platform }, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }



    async registerMobileDeounce() {
        this.registerMobileDebouncer.pipe(
            debounceTime(1)
        )
            .subscribe(
                async (token) => {
                    let r = this.registerTerminal(token.value, this.platform.is('ios') ? 'ios' : 'android');
                    await this.session.set('notificationToken', token.value);
                    console.info("register terminal result", token.value, r);

                }
            )
    }


    async registerMoibile() {

        if (this.platform.is('ios') || this.platform.is('android')) {
            try {

                await this.unregisterMoibile();

                PushNotifications.requestPermission().then(result => {
                    if (result.granted) {
                        // Register with Apple / Google to receive push via APNS/FCM
                        try {
                            PushNotifications.register();
                        }
                        catch (ex) {
                            console.info("Push notification register error", ex);
                        }
                    } else {
                        // Show some error
                    }
                });

                // On success, we should be able to receive notifications
                PushNotifications.addListener('registration',
                    async (token: PushNotificationToken) => {
                        this.registerMobileDebouncer.next(token);
                    }
                );

                // Some issue with our setup and push will not work
                PushNotifications.addListener('registrationError',
                    (error: any) => {
                        //alert('Error on registration: ' + JSON.stringify(error));
                    }
                );

                // Show us the notification payload if the app is open on our device
                PushNotifications.addListener('pushNotificationReceived',
                    async (notification: PushNotification) => {
                        //alert('Push received: ' + JSON.stringify(notification));

                        let toast = await this.toastControl.create({
                            message: notification.title,
                            position: 'top',
                            duration: 5000,
                            buttons: [{
                                text: 'OK',
                                role: 'cancel',
                                handler: () => {
                                    console.log('Cancel clicked');
                                }
                            }
                            ]
                        })

                        toast.present();
                    }
                );

                // Method called when tapping on a notification
                PushNotifications.addListener('pushNotificationActionPerformed',
                    (notification: PushNotificationActionPerformed) => {
                        //alert('Push action performed: ' + JSON.stringify(notification));

                        //todo: should go to notification page

                    }
                );
            }
            catch (e) {
                console.info("error in registering terminal", e);
            };
        }
    }

    async unregisterMoibile() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            try {
                let token = await this.session.get("notificationToken") as string;
                if (token) {
                    let r = await this.unregisterTerminal(token, this.platform.is('ios') ? 'ios' : 'android');
                    console.info("unregister terminal result", r);
                }
            }
            catch (ex) {
                console.info("error in unregistering mobile");
            }
        }
    }

    async getCurrentOS(uuidId?: string): Promise<any> {
        return this.http.get(this.idServiceUrl + `/versions/current/OS`, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
            .toPromise();
    }

    async getCurrentSvr(uuidId?: string): Promise<any> {
        return this.http.get(this.idServiceUrl + `/versions/current/Svr`, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
            .toPromise();
    }
}
