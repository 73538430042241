import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-status-tile',
    templateUrl: './status-tile.component.html',
    styleUrls: ['./status-tile.component.scss'],
})
export class StatusTileComponent implements OnInit {

    @Input() status;
    @Input() settings;
    @Input() title;
    @Input() unit;
    @Input() color;
    @Input() size;
    @Input() loading;

    constructor() { }

    ngOnInit() { 
        this.color = 'tertiary';
    }

    isString(o) {
        return typeof o === 'string';
    }
}
