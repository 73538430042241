import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ComponentsModule } from './components/components.module';
import { DnsService } from './services/dns.service';
import { RpcService } from './services/rpc.service';
import { RioService } from './services/rio.service';

import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Zeroconf } from '@ionic-native/zeroconf/ngx';
import { BLE } from '@ionic-native/ble/ngx';
import { PipesModule } from './pipes/pipes.module';
//import { Mixpanel } from '@ionic-native/mixpanel/ngx';
import { LocalServerService } from './services/local-server.service';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    PipesModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    LocalServerService,
    DnsService,
    RpcService,
    RioService,
    BarcodeScanner,
    Zeroconf,
    //Mixpanel,
    BLE,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
