
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, ElementRef, Renderer2, OnChanges, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment'
import { timeout } from 'rxjs/operators';
import { RpcService } from '../../services/rpc.service';

@Component({
    selector: 'app-cam-view',
    templateUrl: './cam-view.component.html',
    styleUrls: ['./cam-view.component.scss'],
})
export class CamViewComponent implements OnChanges {

    ngOnInit() { }


    _src: string = '';
    _format: { width: number, height: number } = { width: 640, height: 480 };
    loaded = false;
    _ratio_Pct = "75%";
    bleMode = false;

    constructor(
        private _elementRef: ElementRef,
        private _renderer: Renderer2,
        private rpc: RpcService,
        private http: HttpClient
    ) {
        this._format = { width: 128, height: 72 };
    }

    @Input() facilityId: string;
    @Input() spaceId: string;
    @Input() uuid: string;
    @Input() portId: string;
    @Input() time: number;


    @Output() click: EventEmitter<string> = new EventEmitter<string>();
    @Output() timeout: EventEmitter<void> = new EventEmitter<void>();

    show() {
        if (this.loaded && this._src) {
            console.info("emiting data")
            this.click.emit(this._src);
        }
        else {
            console.info("no source");
        }
    }

    async getCamLiveImage() {
        console.info("getCamLiveImage")
        if (this.facilityId && this.spaceId && this.portId && this.uuid)
            console.info("getting image")
        try {
            let buff: { buffer: { type: string, data: string } };

            //buff = await this.rpc.call(this.facilityId, this.spaceId, this.uuid, `cameras/capture`, { id: this.portId });
            try {
                buff = await this.http.post(`http://bf-${this.uuid}/rpc/v4/cameras/capture`, { id: this.portId }) .pipe(
                    timeout(2000)
                ).toPromise() as any;
            }
            catch (ex) {
                buff = await this.rpc.call(this.facilityId, this.spaceId, this.uuid, `cameras/capture`, { id: this.portId }, true);
            }

            console.info("buff", buff);

            if (buff && buff.buffer) {
                return "data:image/jpeg;base64," + this.arrayBufferToBase64(buff.buffer.data);
            }
            else {
                console.info("no image, and src is null")
                if (this._src == null)
                    return "/assets/img/640_360-na.png";
                else
                    return this._src;
            }
        }
        catch (ex) {
            console.info("error in getting image", ex);
            this.timeout.emit();
        }

    }


    @Input() set ratio(ratio: { width: number, height: number }) {
        if (ratio) {
            console.info("ratio", ratio)
            this._format.height = ratio.height;
            this._format.width = ratio.width;
            this._ratio_Pct = (this._format.height / this._format.width * 100) + '%';
            console.info("new ratio", this._ratio_Pct)
        }
    }

    ngOnChanges() {
        this._ratio_Pct = (this._format.height / this._format.width * 100) + '%';
        // Conserve aspect ratio (see: http://stackoverflow.com/a/10441480/1116959)
        //this._renderer.setStyle(this._elementRef.nativeElement, 'padding', '0px 0px ' + ratio_height + ' 0px');
        this.getImage();
    }


    _requestTime: moment.Moment;
    _photoPromise: any;
    private getImage() {
        if (!this._photoPromise || this._requestTime < moment().add(-1, "second")) {
            if (!this._requestTime || this._requestTime < moment().add(-2, "seconds")) {
                this._photoPromise = this.getCamLiveImage();
                this._photoPromise.then((image64: string) => {
                    if (image64) {
                        this._src = `${image64}`;
                        this.loaded = true;
                        this._photoPromise = null;
                    }
                });

            }
        }
    }

    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

}
