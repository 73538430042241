import { Injectable, ɵCompiler_compileModuleAndAllComponentsAsync__POST_R3__ } from '@angular/core';
import { DnsService } from './dns.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { SessionService } from './session.service';
import { RioService } from './rio.service';
import { catchError, timeout } from 'rxjs/operators';
import { TimeoutError, Observable, Subject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { LocalServerService } from './local-server.service';
import { Space } from '../main/entities/facility';
import { CheckboxControlValueAccessor } from '@angular/forms';


export interface IRpcContext {
    cid: string;
    query?: object
    body?: object;
    header?: { [key: string]: string }
}


@Injectable({
    providedIn: 'root'
})
export class RpcService {

    constructor(
        private dns: DnsService,
        private http: HttpClient,
        private session: SessionService,
        private rio: RioService,
        private platform: Platform,
        private localServer: LocalServerService
    ) { }

    async getScorecard(facilityId: string, spaceId: string, uuid: string) {
        return this.call(facilityId, spaceId, uuid, "system/getScorecard", { withModule: true });
    }

    async getScorecardTargets(facilityId: string, spaceId: string, uuid: string) {
        return this.call(facilityId, spaceId, uuid, 'system/getScorecardTargets')
    }

    async audit(facilityId: string, spaceId: string, uuid: string) {
        return this.call(facilityId, spaceId, uuid, 'app/audit')
    }

    groupCall(facilityId: string, spaceId: string, uuids: string[], command: string, param?: object, useRemote?: boolean) {
        let subject = new Subject<{ uuid: string, result: any }>();
        uuids.forEach(uuid => { subject.next({ uuid: uuid, result: this.call(facilityId, spaceId, uuid, command, param, useRemote) }) });

        return subject;
    }

    async call(facilityId: string, spaceId: string, uuid: string, command: string, param?: object, useRemote?: boolean) {

        if (facilityId && uuid == 'all' && this.localServer.endpoint) {

            let space: Space = await this.localServer.getSpace(facilityId, spaceId)
            if (space && space.units) {
                let r = space.units.map(unit => {
                    let ureq: Observable<object>;

                    if (command.indexOf('/get') > 0 || command == 'app/audit' || command=='app/pingSpace' || command == 'app/ping' || command == 'app/hello') {
                        ureq = this.http.get(`http://bf-${unit.controllerUUID}/rpc/v4/${command}`, { params: param as any });
                    }
                    else {
                        ureq = this.http.post(`http://bf-${unit.controllerUUID}/rpc/v4/${command}`, param,);
                    }

                    return ureq
                        .pipe(
                            timeout(command.indexOf("cameras/capture") > 0 ? 30000 : 3000)
                        )
                        .toPromise();
                })

                return r;
            }
            else {
                console.error("No space, or units found");
            }
        }
        else if (uuid) {
            let endpoint = await this.dns.getEndpoint(uuid);
            let url: string;
            let token: string;
            let useLocal = false;
            let headers: any;

            if (endpoint && endpoint.url && !useRemote) {
                url = `http://${endpoint.url}/rpc/v4/${command}`;
                console.info("url", url);
                useLocal = true;
                headers = {};
            }
            else if (facilityId) {
                url = `https://rio.growos.io/rpc/v4/${command}`;
                console.info("cloud url", url);
                token = await this.session.getAuthToken();
                useLocal = false;
                headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token, 'targetUUID': uuid, 'facilityId': facilityId, 'spaceId': spaceId ||'' };
            }
            else {
                throw ("The device is not connectable. This function is available on connected network only.");
            }

            try {
                let req: Observable<object>;
                if (command.indexOf('/get') > 0 || command == 'app/audit' || command == 'app/ping' || command == 'app/hello' || command.indexOf("cameras/capture") > 0)
                    req = this.http.get(url, { params: param as any, headers: headers });
                else
                    req = this.http.post(url, param, { headers: headers });

                let r: (any[] | { [key: string]: any, error?: any }) = await req
                    .pipe(
                        timeout((command.indexOf("cameras/capture") > 0 || command.indexOf("database/list") > 0 )? 30000 : (useLocal ? 5000 : 60000))
                    )
                    .toPromise();

                if (r && r.error) {
                    throw (r.error)
                }
                else {
                    if (r && r.hasOwnProperty("body"))
                        return r.body;
                    else
                        return r;
                }
            }
            catch (ex) {
                console.info("call ex", JSON.stringify(ex));
                if (useLocal && (ex instanceof TimeoutError)) {
                    this.dns.clear();
                    if (command.indexOf("cameras/capture") < 0) //no re-try for local images 
                        return this.call(facilityId, spaceId, uuid, command, param, true);
                }
                else {
                    if (useLocal)
                        return this.call(facilityId, spaceId, uuid, command, param, true);
                    throw (ex);
                }
            }
        }
        else {
            throw ("UUID is not specified.")
        }
    }

}
