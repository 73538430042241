

export class Monitor {
    id?: string;
    name: string;
    configuration: {
        Enabled: boolean,
        Debounce?: number,
        UpperLimit?:number,
        LowerLimit?: number,
        OffTargetLimit?:number,
        Custom?:boolean,
        Unit?:string,
        [ key: string]: any
    };
    userId?: string
}