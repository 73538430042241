import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RioService } from '../../services/rio.service';
import { ConfirmPage } from '../../auth/confirm/confirm.page';
import { SessionService } from '../../services/session.service';
import { MenuController, ModalController } from '@ionic/angular';

@Injectable()
export class FacilityListResolver implements Resolve<Observable<{ name: string, description: string, id: string }>> {
    constructor(
        public rio: RioService,
        public session: SessionService,
        public router: Router,
        public modalController: ModalController,
        public menuController: MenuController
    ) {

    }

    async resolve(route: ActivatedRouteSnapshot) {
        this.menuController.enable(true, 'left');

        let userIdentity = await this.session.getUser();
        if (userIdentity) {
            if (!userIdentity.user.confirmed) {
                let modal = await this.modalController.create({
                    component: ConfirmPage
                });
                modal.present();
                const { data } = await modal.onDidDismiss();

                if (data && data.confirmed) {

                }
                else {
                    this.router.navigate(['/auth/login'], { replaceUrl: true });
                }
            }
        }
        else {
            this.router.navigate(['/auth/login'], { replaceUrl: true });
        }

        let facilities = await this.rio.getFacilities();

        let toShowFullList = (route.queryParamMap.get("listAll") == 'true');

        console.info("route.queryParamMap.get(`listAll`)", route.queryParamMap.get("listAll"));


        if (!toShowFullList) {
            if (facilities.length == 1) {
                this.router.navigate(['/app/facilities/' + facilities[0].id], { state: { facility: facilities[0] } });
            }
        }

        return facilities;
    }
}