import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment'

@Component({
    selector: 'app-status-button',
    templateUrl: './status-button.component.html',
    styleUrls: ['./status-button.component.scss'],
})
export class StatusButtonComponent implements OnInit {

    @Output() countDownFinished: EventEmitter<void> = new EventEmitter();
    @Input() unit: any;
    countDownTimer: any;
    countDown: number = null;

    @Input() set autoPilotAt(v) {

        if (v) {
            this.countDown = moment(v).diff(moment(), 'seconds');
            //console.info("this.countDown", this.countDown);

            if (this.countDownTimer) {
                clearInterval(this.countDownTimer);
                this.countDownTimer = null;
            }

            this.countDownTimer = setInterval(() => {
                this.countDown = moment(v).diff(moment(), 'seconds');
                if (this.countDown <= 0) {
                    this.countDown = null;
                    clearInterval(this.countDownTimer);
                    this.countDownTimer = null;
                    this.unit.mode = 'auto';
                    setTimeout(() => {
                        this.countDownFinished.emit();
                    }, 1000);
                }
                //console.info("countdown", this.countDown);

            }, 1000);
        }
        else {
            if (this.countDownTimer) {
                clearInterval(this.countDownTimer);
                this.countDownTimer = null;
            }
        }
    };

    @Output() click: EventEmitter<void> = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    clicked() {
        console.info("emit")
        this.click.emit();
        return false;
    }

}
