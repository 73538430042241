import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-status-text',
    templateUrl: './status-text.component.html',
    styleUrls: ['./status-text.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StatusTextComponent implements OnInit {

    constructor() { }

    htmlCode: string;
    ngOnInit() { }

    @Input() set text(v: string) {
        if (v) {
            this.htmlCode = v.replace(/\t/g, "<span class='tab'>&nbsp;</span>")
                .replace(/\n/g, '<br>')
                .replace(" ", "&nbsp;")
                .replace(/✓/g, "<span class='success'>✓</span>")
                .replace(/ x /g, "<span class='warning'> x </span>");
        }
    }

}
