import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { SessionService } from '../../services/session.service';
import { RioService } from '../../services/rio.service';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.page.html',
    styleUrls: ['./confirm.page.scss'],
})
export class ConfirmPage implements OnInit {

    code: string = "------";
    errorMessage = "";
    successMessage = "";

    constructor(
        public router: Router,
        public menu: MenuController,
        public session: SessionService,
        public rio: RioService,
        public modalCtrl: ModalController
    ) {

    }

    ngOnInit(): void {
        this.menu.enable(false);
    }

    enterValue = (value: number) => {
        this.errorMessage = "";
        this.successMessage = "";

        let code = this.code.replace(/\-/g, "");
        if (code.length < 6) {
            this.code = (code + value).padEnd(6, "-");
            console.info("this.code", this.code);
        }
    }

    removeLast = () => {
        this.errorMessage = "";
        this.successMessage = "";

        let code = this.code.replace(/\-/g, "");
        if (code.length > 0) {
            this.code = code.substr(0, code.length - 1).padEnd(6, "-");
        }
    }

    verify = async () => {
        this.errorMessage = "";
        this.successMessage = "";

        let userIdentity = await this.session.getUser();
        console.info("Confirming user.", userIdentity);
        if (!userIdentity.user.confirmed) {
            try {
                let result = await this.rio.confirmUser(userIdentity.user.email, this.code);
                if (result.error) {
                    this.errorMessage = result.error;
                        this.successMessage = "";
                }
                else {
                    if (result.confirmed) {
                        userIdentity.user.confirmed = true;
                        await this.session.setUser(userIdentity);
                        await this.rio.registerMoibile();
                        this.modalCtrl.dismiss(result);
                    }
                    else {
                        this.errorMessage = "Incorrect Code";
                        this.successMessage = "";
                    }
                }
            }
            catch (ex) {
                console.error("Error in confirming user", userIdentity.user);
                this.errorMessage = ex.statusText;
            }
        }
        else {
            console.info("Not logged in.")
        }
    }

    sendCode = async () => {
        try {
            let r = await this.rio.requestConfirmationCode((await this.session.getUser()).user.email);
            if (!r.error) {
                this.successMessage = "The verification has been sent to your email."
            }
            else {
                this.errorMessage = r.error;
                this.successMessage = "";
            }
        }
        catch (ex) {
            this.errorMessage = ex.statusText;
            this.successMessage = "";
        }
    }

    close = () => {
        this.modalCtrl.dismiss({ confirmed: false });
    }

}
