import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { Stage } from '../../main/entities/growplan';


@Component({
    selector: 'app-growplan-view',
    templateUrl: './growplan-view.component.html',
    styleUrls: ['./growplan-view.component.scss'],
})
export class GrowplanViewComponent implements OnInit {

    _growplan;

    toGoDays: number;
    currentDay: number;
    pct: number;
    cyclesLapsedNotCounted: number = 0;
    currentStage: Stage

    constructor() { }

    ngOnInit() { }

    @Input() set growplan(val: any) {
        this._growplan = val;
        if (val) {
            if (!this._growplan.currentStageIndex)
                this._growplan.currentStageIndex = 0;

            if (this._growplan.progress == null || this._growplan.progress.finished == null) {
                this._growplan.progress = { finished: [], updateTime: moment().valueOf() };
            }

            this.toGoDays = this.toGo();
            this.currentDay = this.getCurrentDay();
            this.pct = this.currentDay / (this.currentDay + this.toGoDays);
            this.currentStage = this.getCurrentStage();
        }
    }

    @Input() readonly: boolean;w
    @Output() create: EventEmitter<any> = new EventEmitter();
    @Output() edit: EventEmitter<any> = new EventEmitter();
    @Output() delete: EventEmitter<any> = new EventEmitter();

    getCurrentDay() {
        if (this._growplan) {
            if (this._growplan.context && this._growplan.context.startTime) {
                return Math.floor( moment().diff(moment(this._growplan.context.startTime), 'day')) + 1;
            }
            else {
                if (this._growplan.progress && this._growplan.progress.finished) {
                    //console.info('progress', this._growplan.progress);

                    let cyclesLapsed = - Math.floor(moment(this._growplan.progress.updateTime).diff(moment(), 'd'));

                    return this._growplan.progress.finished.map(f => f.cycleCount).reduce(
                        (a, b) => a + b, 0
                    ) + 1 + cyclesLapsed;
                }
                else {
                    return this._growplan.stages.map(stage => stage.cyclesTillFinish).reduce((n1, n2) => n1 + n2, 0) - this.toGo() + 1;
                }
            }
        }
        else {
            return 0;
        }
    }

    getCurrentStage() {

        if (this._growplan) {
            let dayCounts = this._growplan.stages.map(s => s.cyclesTillFinish).reduce((n1, n2) => n1 + n2, 0);

            if (this._growplan.context && this._growplan.context.endTime)
                dayCounts = Math.round( (this._growplan.context.endTime - moment().valueOf()) / (24 * 3600000));
            for (let i = this._growplan.stages.length - 1; i >= 0; i--) {
                dayCounts -= this._growplan.stages[i].cyclesTillFinish;
                if (dayCounts <= 0) {
                    return this._growplan.stages[i];
                }
            }
        }
        else {
            return null;
        }
    }

    requestNew() {
        this.create.emit();
    }

    requestEdit() {
        this.edit.emit();
    }

    requestDelete() {
        this.delete.emit();
    }

    toGo() {
        if (this._growplan.context && this._growplan.context.endTime) {
            console.info("this._growplan.context.endTime ", this._growplan.context.endTime)
            return moment(this._growplan.context.endTime).diff(moment(), 'day') + 1;
        }
        else {
            console.info("this._growplan.stages.map(stage => stage.cyclesTillFinish).reduce((n1, n2) => n1 + n2, 0);", this._growplan.stages.map(stage => stage.cyclesTillFinish))
            return this._growplan.stages.map(stage => stage.cyclesTillFinish).reduce((n1, n2) => n1 + n2, 0);
        }
    }

}
