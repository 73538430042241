import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RioService } from '../../services/rio.service';

@Component({
    selector: 'app-alert-item',
    templateUrl: './alert-item.component.html',
    styleUrls: ['./alert-item.component.scss'],
})
export class AlertItemComponent implements OnInit {

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public rio: RioService
    ) { }

    alert;
    title: string;
    color: string;
    status: string;


    @Input() set value(v) {
        this.alert = v;
        if (v && v.data) {
            this.title = v.data.type.replace("scorecard.", "").replace(".", " ");
        }

        if (v.events) {
            v.events.sort((a, b) => b.timestamp - a.timestamp)
        }

        this.color = v.active ? 'danger' : '';
        this.status = v.status ? v.status : 'Recovered';
    }

    ngOnInit() { }



    configureMonitor(monitorId: string) {
        this.router.navigate(['monitors/' + monitorId], { relativeTo: this.route });
    }

    async closeCase() {
        let alert: any = await this.rio.updateCase(this.alert.facilityId, { event: { title: "Closed by user" }, caseId: this.alert.id, closing: true });
        if (alert && !alert.error) {
            this.alert = alert;
        }
        else {
            if (alert)
                alert(alert.error);
        }
    }

}
