import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'onoff'
})
export class OnoffPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        if (value == null) {
            return "--"
        }
        else {
            if (value)
                return "On"
            else
                return "Off"
        }
    }

}
