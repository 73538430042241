import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Unit } from '../../main/entities/unit';

@Component({
    selector: 'app-unit-list',
    templateUrl: './unit-list.component.html',
    styleUrls: ['./unit-list.component.scss'],
})
export class UnitListComponent implements OnInit {

    @Input() units: Unit[];
    @Input() editMode: boolean;
    @Output() changed: EventEmitter<void> = new EventEmitter();
    @Output() statusRequested: EventEmitter<any> = new EventEmitter();
    @Output() notesRequested: EventEmitter<{ unit: Unit, event: any }> = new EventEmitter();
    @Output() cameraRequested: EventEmitter<{ unit: Unit, event: any }> = new EventEmitter();
    @Output() dataRequested: EventEmitter<{ unit: Unit, event: any }> = new EventEmitter();
    
    @Output() gotoUnit: EventEmitter<{ unit: Unit, event: any }> = new EventEmitter();
    order = 1;
    imageMode = false;


    newUnit: any = {};

    currentUnit: any;

    constructor(
        private router: Router
    ) { }

    ngOnInit() { }

    sortByUnit() {
        if (this.units.length > 1)
            this.order = this.units[0].id.toUpperCase() > this.units[1].id.toUpperCase() ? 1 : -1
        this.units.sort((u1, u2) => u1.id.toUpperCase() > u2.id.toLocaleUpperCase() ? this.order : - this.order)
    }

    sortByXPS() {
        if (this.units.length > 1)
            this.order = this.units[0].controllerUUID > this.units[1].controllerUUID ? 1 : -1
        this.units.sort((u1, u2) => u1.controllerUUID > u2.controllerUUID ? this.order : - this.order)
    }

    sortByLocation() {
        if (this.units.length > 1)
            this.order = this.units[0].location.toUpperCase() > this.units[1].location.toUpperCase() ? 1 : -1
        this.units.sort((u1, u2) => u1.location.toUpperCase() > u2.location.toUpperCase() ? this.order : - this.order)
    }

    sortByStatus() {
        if (this.units.length > 1)
            this.order = this.units[0].mode.startsWith('a') ? -1 : 1
        this.units.sort((u1, u2) => u1.mode == u2.mode ? 0 : u1.mode > u2.mode ? this.order : - this.order)
    }

    add() {
        let unit = JSON.parse(JSON.stringify(this.newUnit));
        unit.controllerUUID = (unit.controllerUUID as string || '').toUpperCase();
        unit.id = (unit.id as string || '').toUpperCase();
        unit.location = (unit.location as string || `A${this.units.length + 1}`).toUpperCase();
        unit.supply = (unit.supply as string || 'L1').toUpperCase();
        this.units.push(unit);
        this.newUnit = {};
        this.changed.next();
    }

    delete(i: number) {
        if (this.units) {
            this.units.splice(i, 1);
        }
        this.changed.next();
    }

    goto(unit: Unit, event) {
        if (!this.editMode)
            this.gotoUnit.emit({ unit: unit, event: event })
    }

    openNotes(unit: Unit, event) {
        this.notesRequested.emit({ unit: unit, event: event });
    }

    showCameras(unit: Unit, event) {
        this.cameraRequested.emit({ unit: unit, event: event })
    }

    showData(unit: Unit, event) {
        this.dataRequested.emit({ unit: unit, event: event })
    }

    validNewUnit(unit) {
        return unit.controllerUUID != null && unit.id != null;
    }

    changeMode(event) {
        console.info("list item change mode ");
        event.stopPropagation();
    }

    countDownFinished(unit) {
        this.statusRequested.emit(unit);
    }

    async checkNewValues() {
        if (this.newUnit.location || this.newUnit.id || this.newUnit.controllerUUID || this.newUnit.supply) {

        }
    }
    receiveFocus(unit) {
        this.currentUnit = unit;
    }
}


