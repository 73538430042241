import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
import { GrowPlan } from '../main/entities/growplan';
import * as moment from 'moment';
import jwtDecode from 'jwt-decode';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    user: { user: { id: string, email: string, name: string, confirmed: boolean }, authToken: string, refreshToken: string }
    authTokenExpires: number = 0;
    getRefreshToken: (refreshToken: string) => Promise<{ authToken: string }>;

    constructor() {
        this.restore();
    }

    async restore() {
        this.user = await this.getUser();
        if (this.user) {
            await this.setTokenExpireTime(this.user.authToken)
        }
    }

    get = async (name: string) => {
        const { value } = await Storage.get({ key: name });
        if (value) {
            try {
                return JSON.parse(value);
            }
            catch (ex) {
                //console.info("value", value);
                null;
            }
        }
        else {
            return null;
        }
    }

    set = async (name: string, value: any) => {
        await Storage.set({ key: name, value: JSON.stringify(value) });
    }


    getLibrary = async (facility: string): Promise<{ growplans: GrowPlan[] }> => {

        let key = `library/${facility ? facility : 'personal'}`;
        let pl = await this.get(key);
        if (pl)
            return pl;
        else
            return { growplans: [] };
    }

    setLibrary = async (library: { growplans: GrowPlan[] }, facility: string) => {
        let key = `library/${facility ? facility : 'personal'}`;
        await this.set(key, library);
    }

    saveToLibrary = async (growplan: GrowPlan, facility: string) => {
        if (growplan) {
            let library = (await this.getLibrary(facility) as { growplans: GrowPlan[] }) || { growplans: [] };
            if (library && library.growplans.filter(plan => plan.id === growplan.id).length > 0) {
                for (let i = 0; i < library.growplans.length; i++) {
                    if (library.growplans[i].id === growplan.id) {
                        library.growplans[i] = growplan;
                    }
                }
                await this.setLibrary(library, facility);
            }
            else {
                library.growplans.push(growplan);
                await this.setLibrary(library, facility);
                //console.info("personal library", await this.getPersonalLibrary())
            }
        }
    }

    setUser = async (user: { user: { id: string, email: string, name: string, confirmed: boolean }, authToken: string, refreshToken: string }) => {
        this.user = user;
        await Storage.set({ key: "userIdentity", value: JSON.stringify(user) });
        this.setTokenExpireTime(user.authToken);

    }

    async getAuthToken() {
        if (moment().isSameOrAfter(moment(this.authTokenExpires))) {
            let newToken = await this.getRefreshToken(this.user.refreshToken);
            this.user.authToken = newToken.authToken;
            await this.setUser(this.user);
            return this.user.authToken;
        }
        else {
            return this.user.authToken;
        }
    }

    async setTokenExpireTime(authToken) {

        let decoded = jwtDecode(authToken);
        this.authTokenExpires = decoded.exp * 1000;
        //console.info("expires", this.authTokenExpires);
    }

    getUser = async (): Promise<{ user: { id: string, email: string, name: string, confirmed: boolean }, authToken: string, refreshToken: string }> => {

        if (this.user)
            return this.user;
        else {
            const { value } = await Storage.get({ key: 'userIdentity' });
            if (value) {
                this.user = JSON.parse(value);
                return this.user;
            }
            else {
                return null;
            }

        }
    }

    clear = async () => {
        await Storage.clear();
        this.user = null;
        this.authTokenExpires = null;
    }

    makeId(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }




    async getAlerts(facilityId: string) {
        let alertStore = await this.get(facilityId + "alerts");
        if (alertStore)
            return alertStore;
        else
            return [];
    }

    async setAlerts(facilityId: string, alerts: any) {
        await this.set(facilityId + "alerts", alerts);
    }

    async clearCachedAlerts(facilityId: string) {
        await this.set(facilityId, null);
    }
}
