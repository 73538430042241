import { HttpClient } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Zeroconf } from '@ionic-native/zeroconf/ngx';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import { timeout } from 'rxjs/operators';
import { Facility } from '../main/entities/facility';
import { Unit } from '../main/entities/unit';

@Injectable({
    providedIn: 'root'
})
export class LocalServerService {

    endpoint: string = null;
    subscription: any;
    facility: Facility;
    accessPin: string;
    library: string;

    constructor(
        public platform: Platform,
        private zeroconf: Zeroconf,
        private http: HttpClient
    ) {

    }

    discover() {
        if (this.platform.is('cordova')) {
            if (!this.subscription) {
                this.subscription = this.zeroconf.watch('_http._tcp,', 'local.').subscribe(
                    async result => {
                        //console.info("found service", result.service);
                        if (result.action == 'resolved') {
                            //console.log('service added', result.service);
                            if (result.service.name.startsWith("GrowOS Server")) {
                                console.info("found GrowOS server service", result);
                                let ips = (result.service.ipv4Addresses);
                                if (ips.length > 0) {
                                    if (ips[0].startsWith("192.168.") || ips[0].startsWith("172.") || ips[0].startsWith("10.")) {
                                        this.endpoint = ips[0];
                                        await this.queryFacilityOnServer()
                                    }
                                }
                            }
                        } else {
                            //console.log('service removed', result.service);

                        }
                    },
                );
            }
        }
    }


    queryFacilityOnServer = async () => {
        if (this.endpoint) {
            try {

                let r = await this.http.get(`http://${this.endpoint}/rpc/v4/app/getFacility`).pipe(timeout(5000)).toPromise();
                console.info("queryFacilityOnServer r", r);
                if (r) {
                    this.facility = r as Facility;
                    console.info("Got facility", this.facility);
                }
                else {
                    console.info("Error: no facility found.");
                }
                return this.facility;
            }
            catch (ex) {
                console.info("queryFacilityOnServer ex", ex);
                this.facility = null;
                return null;
            }
        }
        else
            return null;
    }



    updateFacilityOnServer = async (facility: Facility, noSync?: boolean) => {
        try {
            this.facility = facility;
            let r: any = await this.http.post(`http://${this.endpoint}/rpc/v4/app/updateFacility`, { facility: this.facility, noSync: noSync }).pipe(timeout(1000)).toPromise();
            if (r && !r.error)
                this.facility = r as Facility;
        }
        catch (ex) {

        }
    }

    //apis
    async getFacility(facilityId: string): Promise<any> {
        if (this.endpoint) {
            await this.queryFacilityOnServer();
        }
        if (this.facility && this.facility.id === facilityId)
            return this.facility;
        else
            return null;
    }

    async getFacilityAcl(facilityId: string): Promise<any> {

        if (this.endpoint) {
            await this.queryFacilityOnServer();
        }
        if (this.facility && this.facility.id === facilityId)
            return this.facility.acl;
        else
            return null;
    }

    async updateFacility(facilityId: string, facility: any): Promise<any> {
        this.facility = await this.queryFacilityOnServer();
        if (this.endpoint && this.facility && this.facility.id == facilityId) {
            this.facility = { ... this.facility, ...facility };
            await this.updateFacilityOnServer(this.facility);
            return this.facility;
        }
        else
            return null;
    }

    async upateFacilityAcl(facilityId: string, email: string, role: string): Promise<any> {
        throw ("not avaialbe in local mode.");
    }

    async getFacilityAvailableDeviceUUIDs(facilityId: string): Promise<any> {
        this.facility = await this.queryFacilityOnServer();
        if (this.endpoint && this.facility && this.facility.id == facilityId) {
            let uuids = this.facility.deviceUUIDs;
            let used = this.facility.spaces.map(space => space.units).reduce((su1, su2) => su1.concat(su2)).map(u => u.controllerUUID)
            return uuids.filter(uuid => !used.includes(uuid));
        }
        else
            return null;
    }


    async deleteSpace(facilityId: string, spaceId: string): Promise<any> {
        this.facility = await this.queryFacilityOnServer();
        if (this.endpoint && this.facility && this.facility.id == facilityId) {
            this.facility.spaces = this.facility.spaces.filter(space => space.id != spaceId);
            await this.updateFacilityOnServer(this.facility);
            return this.facility;
        }
        else
            return null;
    }

    async getSpaces(facilityId: string): Promise<any> {
        this.facility = await this.queryFacilityOnServer();
        if (this.endpoint && this.facility && this.facility.id === facilityId) {
            return this.facility.spaces;
        }
        else {
            return null;
        }
    }

    async createNewSpace(facilityId: string, spaceInfo: any): Promise<any> {
        this.facility = await this.queryFacilityOnServer();
        if (this.endpoint && this.facility && this.facility.id === facilityId) {
            spaceInfo.acl = this.facility.acl;
            spaceInfo.id = moment().valueOf() + '';
            this.facility.spaces.push(spaceInfo);
            console.info("facility with new space info", this.facility);
            await this.updateFacilityOnServer(this.facility);
            return spaceInfo;
        }
        else
            return null;
    }

    async getSpace(facilityId: string, spaceId: string): Promise<any> {
        this.facility = await this.queryFacilityOnServer();
        if (this.endpoint && this.facility && this.facility.id === facilityId) {
            let spaces = this.facility.spaces.filter(space => space.id === spaceId);
            if (spaces.length > 0)
                return spaces[0];
            else
                return null;
        }
        else
            return null;
    }

    async updateSpaceInfo(facilityId: string, spaceId: string, spaceInfo: any): Promise<any> {
        this.facility = await this.queryFacilityOnServer();

        if (this.endpoint && this.facility && this.facility.id === facilityId) {
            spaceInfo.acl = this.facility.acl;

            this.facility.spaces.forEach((space, index) => {
                if (space.id === spaceId) {
                    this.facility.spaces[index] = { ...this.facility.spaces[index], ...spaceInfo };
                }
            })

            await this.updateFacilityOnServer(this.facility);
            return spaceInfo;
        }
        else
            return null;
    }

    async updateSpaceController(facilityId: string, spaceId: string, uuid: string): Promise<any> {
        this.facility = await this.queryFacilityOnServer();

        if (this.endpoint && this.facility && this.facility.id === facilityId) {
            this.facility.spaces.forEach((space, index) => {
                if (space.id === spaceId) {
                    this.facility.spaces[index].controllerUUID = uuid;
                }
            })

            await this.updateFacilityOnServer(this.facility);
            return { controllerUUID: uuid };
        }
        else
            return null;
    }

    async updateSpaceUnits(facilityId: string, spaceId: string, units: any[]): Promise<any> {
        this.facility = await this.queryFacilityOnServer();
        if (this.endpoint && this.facility && this.facility.id === facilityId) {
            this.facility.spaces.forEach((space, index) => {
                if (space.id === spaceId) {
                    this.facility.spaces[index].units = units;
                    space.deviceUUIDs = space.units.map(u => u.controllerUUID);

                    if (!space.deviceUUIDs.includes(space.controllerUUID)) {
                        if (space.deviceUUIDs.length > 0) {
                            space.controllerUUID = space.deviceUUIDs[0];
                        }
                        else {
                            space.controllerUUID = null;
                        }
                    }

                }
            })

            await this.updateFacilityOnServer(this.facility);
            return units;
        }
        else
            return null;
    }

    async updateSpaceGrowPlan(facilityId: string, spaceId: string, growplan: any) {
        this.facility = await this.queryFacilityOnServer();
        console.info("this.facility", this.facility);

        if (this.endpoint && this.facility && this.facility.id === facilityId) {
            let units: Unit[];
            this.facility.spaces.forEach((space, index) => {
                if (space.id === spaceId) {
                    this.facility.spaces[index].growplan = growplan;
                    units = space.units;
                }
            })

            console.info("this.facility after growplan", this.facility)
            await this.updateFacilityOnServer(this.facility);
            this.facility = await this.queryFacilityOnServer();
            return units;
        }
        else
            return null;
    }

    async removeSpaceGrowPlan(facilityId: string, spaceId: string) {
        this.facility = await this.queryFacilityOnServer();
        if (this.endpoint && this.facility && this.facility.id === facilityId) {
            let units;
            this.facility.spaces.forEach((space, index) => {
                if (space.id === spaceId) {
                    this.facility.spaces[index].growplan = null;
                    units = space.units;
                }
            })
            await this.updateFacilityOnServer(this.facility);
            return units;
        }
        else
            return null;
    }


    async provisionDeviceToFacility(facilityId: string, uuid: string): Promise<any> {
        this.facility = await this.queryFacilityOnServer();
        if (this.facility && facilityId == this.facility.id) {
            if (!this.facility.deviceUUIDs.includes(uuid)) {
                this.facility.deviceUUIDs.push(uuid);
            }

            console.info("provisionDeviceToFacility", this.facility);
            await this.updateFacilityOnServer(this.facility);
            console.info("updateFacilityOnServer", this.facility, true)
            let r = { status: 'unused', facilityId: this.facility.id, facilityName: this.facility.name, facilityServerUUID: this.endpoint.replace("bf-", "").toUpperCase() };
            return r;
        }
    }

    async deprovisionDeviceFromFacility(facilityId: string, uuid: string): Promise<any> {
        this.facility = await this.queryFacilityOnServer();
        if (this.facility && facilityId == this.facility.id) {
            this.facility.deviceUUIDs = this.facility.deviceUUIDs.filter(uid => uid != uuid);
        }
        await this.updateFacilityOnServer(this.facility, true);
        let r = { status: 'unprovisioned' };
        return r;
    }



    async queryLibraryOnServer() {
        try {
            let r = await this.http.get(`http://${this.endpoint}/rpc/v4/app/getLibrary`).pipe(timeout(2000)).toPromise();
            if (r) {
                this.library = r as any;
            }
            else {
                console.info("Error: no facility found.");
            }
            return this.library;
        }
        catch (ex) {
            return null;
        }
    }

    async updateLibraryOnServer(library: any) {
        try {

            this.library = library;
            let r = await this.http.post(`http://${this.endpoint}/rpc/v4/app/updateLibrary`, { library: this.library }).pipe(timeout(1000)).toPromise();
            if (r)
                this.library = r as any;

            return r;
        }
        catch (ex) {
            return null;
        }
    }

    /*
    async getCases(facilityId: string, spaceId: string, from: number, to: number, limit: number) {
        let params: any = {};
        if (from)
            params.from = from;

        if (to)
            params.to = to;

        if (limit)
            params.limit = limit;

        return this.http.get(this.idServiceUrl + `/account/facilities/${facilityId}/spaces/${spaceId}/cases`, { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    async updateCase(facilityId: string, event: {
        caseId: string;
        event: {
            title: string;
            details?: string;
            data?: any;
            context?: { [key: string]: any };
        };
        closing: boolean;
    }) {
        return this.http.post(this.idServiceUrl + `/account/facilities/${facilityId}/cases/${event.caseId}`, event, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await this.session.getAuthToken() }) })
            .toPromise();
    }

    */

}
