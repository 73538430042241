import { Component, OnInit, Input } from '@angular/core';
import { Monitor } from '../../main/entities/alert';

@Component({
    selector: 'app-monitor',
    templateUrl: './monitor.component.html',
    styleUrls: ['./monitor.component.scss'],
})
export class MonitorComponent implements OnInit {

    mode = 1;
    _monitor: Monitor
    title: string


    @Input() set monitor(v: Monitor) {
        v = v || { configuration: {} } as any;
        v.configuration = v.configuration || {} as any;
        this._monitor = v;

        if (v.name)
            this.title = v.name.replace("scorecard.", "").replace(".", " ");
        
        console.info("v.name", v.name);

        if (v.configuration && v.configuration.OffTargetLimit != null) {
            this.mode = 1;
        }
        else {
            this.mode = 0;
        }
    }

    @Input() unit: string;

    constructor() { }

    ngOnInit() {
        this._monitor = { configuration: {} } as any;
    }

    changeType(e) {
        if (e.target.value == 0) //absolute
        {
            delete this._monitor.configuration.OffTargetLimit;
        }
        else //relative
        {
            delete this._monitor.configuration.LowerLimit;
            delete this._monitor.configuration.UpperLimit;
        }
    }

}
