import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RioService } from '../../services/rio.service';
import { SessionService } from '../../services/session.service';
import { ModalController } from '@ionic/angular';

@Injectable()
export class SpaceListResolver implements Resolve<Observable<any>> {
    constructor(
        public route: ActivatedRoute,
        public rio: RioService,
        public session: SessionService,
        public router: Router,
        public modalController: ModalController) {

    }

    async resolve(route: ActivatedRouteSnapshot) {

        console.info("space resolver");

        let spaces = await this.rio.getSpaces(route.paramMap.get('facilityId'))
        let autoJump = !route.queryParamMap.get("listAll");

        //disable automump
        /*
        if (spaces && spaces.length === 0 && autoJump) {
            this.router.navigate([`/app/facilities/${route.paramMap.get('facilityId')}/spaces/new`], { relativeTo: this.route });
        }
        else
        */
        return spaces;
    }
}