import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FacilityListResolver } from './main/facility-list/facility-list-resolver';
import { SpaceListResolver } from './main/facility/space-list-resolver';
import { ProvisionResolver } from './main/provision/provision-resolver';
import { ConfirmPageModule } from './auth/confirm/confirm.module';


const routes: Routes = [
    //{ path: '', redirectTo: '/walkthrough', pathMatch: 'full' },
    { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
    { path: 'walkthrough', loadChildren: () => import('./misc/walkthrough/walkthrough.module').then(m => m.WalkthroughPageModule) },
    { path: 'getting-started', loadChildren: () => import('./misc/getting-started/getting-started.module').then(m => m.GettingStartedPageModule) },

    { path: 'auth/login', loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule) },
    { path: 'auth/signup', loadChildren: () => import('./auth/signup/signup.module').then(m => m.SignupPageModule) },
    { path: 'auth/forgot-password', loadChildren: () => import('./auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },

    {
        path: 'app/facilities',
        loadChildren: () => import('./main/facility-list/facility-list.module').then(m => m.FacilityListPageModule),
        resolve: { facilities: FacilityListResolver }
    },
    /*
  {
      path: 'app/facilities',
      loadChildren: () => import('./main/facilities/facilities.module').then(m => m.FacilitiesPageModule)
  },
  */

    {
        path: 'app/facilities/new',
        loadChildren: () => import('./main/facility-new/facility-new.module').then(m => m.FacilityNewPageModule)
    },

    {
        path: 'app/facilities/:facilityId',
        loadChildren: () => import('./main/facility/facility.module').then(m => m.FacilityPageModule),
        resolve: { spaces: SpaceListResolver }
    },

    {
        path: 'app/facilities/:facilityId/spaces/new',
        loadChildren: () => import('./main/space-new/space-new.module').then(m => m.SpaceNewPageModule),

    },

    {
        path: 'app/facilities/:facilityId/spaces/:spaceId',
        loadChildren: () => import('./main/space/space.module').then(m => m.SpacePageModule)
    },
    {
        path: 'app/facilities/:facilityId/spaces/:spaceId/units',
        loadChildren: () => import('./main/units/units.module').then(m => m.UnitsPageModule)
    },
    {
        path: 'app/facilities/:facilityId/spaces/:spaceId/units/:unitId',
        loadChildren: () => import('./main/unit/unit.module').then(m => m.UnitPageModule)
    },

    {
        path: 'app/facilities/:facilityId/acl',
        loadChildren: () => import('./main/acl/acl.module').then(m => m.AclPageModule)
    },

    {
        path: 'app/facilities/:facilityId/library',
        loadChildren: () => import('./main/library/library.module').then(m => m.LibraryPageModule)
    },
    {
        path: 'app/facilities/:facilityId/spaces/:spaceId/alerts',
        loadChildren: () => import('./main/alerts/alerts.module').then(m => m.AlertsPageModule)
    },
    {
        path: 'app/facilities/:facilityId/spaces/:spaceId/library',
        loadChildren: () => import('./main/library/library.module').then(m => m.LibraryPageModule)
    },

    {
        path: 'app/facilities/:facilityId/devices/:uuid/provision',
        loadChildren: () => import('./main/provision/provision.module').then(m => m.ProvisionPageModule)
    },

    {
        path: 'app/provision',
        loadChildren: () => import('./main/provision/provision.module').then(m => m.ProvisionPageModule)
    },
    {
        path: 'app/profile',
        loadChildren: () => import('./main/profile/profile.module').then(m => m.ProfilePageModule)
    },

    {
        path: 'app/growos-server',
        loadChildren: () => import('./auth/login/growos-server/growos-server.module').then(m => m.GrowosServerPageModule)
    },

    { path: 'page-not-found', loadChildren: () => import('./misc/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },
    { path: '**', redirectTo: 'page-not-found' },

]


@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
        ConfirmPageModule
    ],
    exports: [RouterModule],
    providers: [FacilityListResolver, SpaceListResolver, ProvisionResolver]
})
export class AppRoutingModule { }
