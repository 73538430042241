import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ShellModule } from '../shell/shell.module';
import { CheckboxWrapperComponent } from './checkbox-wrapper/checkbox-wrapper.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { CounterInputComponent } from './counter-input/counter-input.component';
import { RatingInputComponent } from './rating-input/rating-input.component';
import { StatusTileComponent } from './status-tile/status-tile.component';
import { UnitListComponent } from './unit-list/unit-list.component';
import { GrowplanViewComponent } from './growplan-view/growplan-view.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { DiagConsoleComponent } from './diag-console/diag-console.component';
import { MonitorComponent } from './monitor/monitor.component';
import { StatusButtonComponent } from './status-button/status-button.component';
import { AlertItemComponent } from './alert-item/alert-item.component';
import { CamViewComponent } from './cam-view/cam-view.component';
import { StatusTextComponent } from './status-text/status-text.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShellModule,
    IonicModule,
    NgxJsonViewerModule
  ],
  declarations: [
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    StatusTileComponent,
    StatusButtonComponent,
    UnitListComponent,
    GrowplanViewComponent,
    DiagConsoleComponent,
    MonitorComponent,
    AlertItemComponent,
    CamViewComponent,
    StatusTextComponent
  ],
  exports: [
    ShellModule,
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    StatusTileComponent,
    StatusButtonComponent,
    UnitListComponent,
    GrowplanViewComponent,
    DiagConsoleComponent,
    MonitorComponent,
    AlertItemComponent,
    CamViewComponent,
    StatusTextComponent
  ]
})
export class ComponentsModule {}
