import { Component, OnInit } from '@angular/core';

import { Platform, MenuController, LoadingController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SessionService } from './services/session.service';
import { Router } from '@angular/router';
import { DnsService } from './services/dns.service';
import { RioService } from './services/rio.service';
//import { Mixpanel } from '@ionic-native/mixpanel/ngx';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: [
        './side-menu/styles/side-menu.scss',
        './side-menu/styles/side-menu.shell.scss',
        './side-menu/styles/side-menu.responsive.scss'
    ]
})
export class AppComponent implements OnInit {

    upgradeAvailable = false;

    appPages = [
        {
            title: 'Facilities',
            url: '/app/facilities',
            ionicIcon: 'grid-outline',
            disabled: false
        },
        {
            title: 'Provision',
            url: '/app/provision',
            ionicIcon: 'qr-code-outline'
        }
    ];
    accountPages = [
        {
            title: 'Profile',
            url: '/app/profile',
            customIcon: './assets/custom-icons/side-menu/contact-card.svg',
            disabled: false
        },
        {
            title: 'Tutorial',
            url: '/walkthrough',
            ionicIcon: 'school-outline',
            disabled: true
        }
    ];

    textDir = 'ltr';

    mixpanelToken = "3158ca9232a6851ab23fa2881a3787ae";

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private session: SessionService,
        private router: Router,
        private dns: DnsService,
        private rio: RioService,
        public menu: MenuController,
        ////public mixpanel: Mixpanel,
        public loadingController: LoadingController,
        public alertController: AlertController

    ) {
        this.initializeApp();
    }

    async initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });

        this.menu.enable(true, 'left');

        try {
            //this.mixpanel.init(//this.mixpanelToken);
        }
        catch (ex) {
            console.info("Mixpanel init failed", ex);
        }

        this.platform.resume.subscribe(async () => {
            
        })
    }

    ngOnInit() {

    }

    async logout() {
        await this.rio.unregisterMoibile();
        await this.session.clear();
        await this.router.navigate(['/auth/login'], { replaceUrl: true });
        //this.mixpanel.track("logout");
    }

    async syncApp() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            let alert = this.alertController.create({
                message: "Mobile app update is available. Restart?",
                buttons: [
                    {
                        role: 'cancel',
                        text: "Cancel"
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            


                        }
                    }
                ]
            })
        }
    }
}
