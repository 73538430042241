import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RioService } from '../../services/rio.service';
import { SessionService } from '../../services/session.service';
import { ModalController } from '@ionic/angular';

@Injectable()
export class ProvisionResolver implements Resolve<Observable<any>> {
    constructor(
        public rio: RioService,
        public session: SessionService,
        public router: Router,
        public modalController: ModalController) {
    }
    async resolve(route: ActivatedRouteSnapshot) {
        let uuid = route.paramMap.get("uuid");
        let facilityId = route.paramMap.get("facilityId");
        if (uuid) {
            try {
                let provisionInfo = await this.rio.queryDeviceProvisionInfo(facilityId, uuid);
                return provisionInfo;
            }
            catch (ex) {
                return null;
            }
        }
        return null;
    }
}