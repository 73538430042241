import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-diag-console',
    templateUrl: './diag-console.component.html',
    styleUrls: ['./diag-console.component.scss'],
})
export class DiagConsoleComponent implements OnInit {

    res: any;

    @Input() calling:boolean;
    @Input() callResult:any;
    @Input() status: any;

    constructor() { }

    ngOnInit() { }

}
